.verdenskart-legend-choropleth {
  margin: 4px 0 12px;
  font-size: 12px;
  color: #333;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.verdenskart-legend-choropleth ul {
  display: flex;
  list-style: none;
  margin: 2px 0;
  padding: 0;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.verdenskart-legend-choropleth li {
  flex: 1 1 auto;
  padding: 0;
  min-height: 10px;
  text-align: center;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 100% !important;
  padding: 0 !important;
  position: static !important;
  line-height: inherit !important;
}

.verdenskart-legend-choropleth li::before{
  display: none;
}
