.verdenskart-popup .maplibregl-popup-content {
  padding: 0;
  box-shadow: 0 1px 5px rgb(0 0 0 / 40%);
}

.verdenskart-popup .maplibregl-popup-content button{
  padding: 1px 6px;
}

.verdenskart-popup h2 {
  font-size: 13px;
  color: #111;
}

.verdenskart-popup p {
  margin: 8px 0 0;
}

.verdenskart-popup.popup-default .maplibregl-popup-content {
  padding: 10px 10px 15px;
}

.verdenskart-popup.popup-default h2 {
  margin: 8px 0 0;
}

.verdenskart-popup.popup-default a {
  padding-top: 8px;
  display: block;
  text-decoration: none;
}

.verdenskart-popup.popup-default a:hover {
  text-decoration: underline;
}

.verdenskart-popup.popup-image {
  padding: 0;
  width: 300px;
  height: 80px;
  cursor: pointer;
}

.verdenskart-popup.popup-image a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.verdenskart-popup.popup-image a img {
  display: block;
  width: 80px;
  height: 80px;
}

.verdenskart-popup.popup-image a h2 {
  margin: 0;
  padding: 0 8px 0 16px;
  max-height: 80px;
}
