.verdenskart-basemap-switcher {
  position: absolute;
  bottom: 0;
  right: 55px;
  background-color: #fff;
  padding: 10px;
}

.verdenskart-basemap-switcher h2 {
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 4px;
}

.verdenskart-basemap-switcher-layers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.verdenskart-basemap-switcher-layers div {
  margin-right: 10px;
}

.verdenskart-basemap-switcher-layers div:last-child {
  margin-right: 0;
}

.verdenskart-basemap-switcher-layers img {
  width: 50px;
  height: 50px;
  border: 3px solid #ddd;
  box-sizing: border-box;
  cursor: pointer;
  max-width: none;
}

.verdenskart-basemap-switcher-layers img:hover {
  border: 3px solid #555;
}

.verdenskart-basemap-switcher-selected {
  border: 3px solid #555 !important;
}

.verdenskart-basemap-switcher-layers span {
  display: block;
  line-height: 12px;
  text-align: center;
  color: #555;
  font-size: 11px;
}
