.verdenskart-loading-spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #005eb8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: verdenskart-spin 1.5s linear infinite;
}

@keyframes verdenskart-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
