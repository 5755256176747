.verdenskart-legend {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 360px;
  max-width: calc(100% - 60px);
  user-select: none;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.verdenskart-legend-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.verdenskart-legend h2 {
  margin: 8px 12px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.verdenskart-legend-toggle {
  color: #333;
  white-space: nowrap;
  padding-right: 10px;
}

.verdenskart-legend-toggle span {
  font-size: 16px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.verdenskart-legend-scrollable {
  overflow-y: auto;
}

.verdenskart-legend-content {
  padding: 0 12px 8px 12px;
}

.verdenskart-legend-content p {
  margin: 0 0 4px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.verdenskart-legend-unit {
  margin-top: 12px;
  font-weight: 500;
}

.verdenskart-legend-columns {
  display: flex;
}

.verdenskart-legend-columns > div {
  flex: 50%;
}

.verdenskart-legend-basic {
  margin: 12px 0 8px;
}
