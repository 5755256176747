.verdenskart-link {
  margin: 6px 0;
}

.verdenskart-link a {
  text-decoration: none;
  color: #0000EE;
}

.verdenskart-link a:hover {
  text-decoration: underline;
  color: #0000EE;
}
