.verdenskart-label .maplibregl-popup-tip {
  display: none;
}

.verdenskart-label .maplibregl-popup-content {
  padding: 2px 5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  background-color: rgba(255, 255, 255, 0.9);
}
