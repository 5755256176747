.verdenskart-data-control {
  position: absolute;
  top: 10px;
  left: 380px;
  user-select: none;
  background-color: #fff;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
  font-size: 13px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
}

.verdenskart-data-control div {
  display: inline;
  padding: 0 5px;
  line-height: 27px;
  cursor: pointer;
}

.verdenskart-data-control-selected {
  background-color: #333;
  color: #fff;
}

.verdenskart-data-control div:hover {
  background-color: #eee;
}

.verdenskart-data-control .verdenskart-data-control-selected:hover {
  background-color: #333;
}

@media only screen and (max-width: 610px) {
  .verdenskart-data-control {
    top: 57px;
    left: 10px;
  }
}
