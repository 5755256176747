.verdenskart-legend-item {
  display: flex;
}

.verdenskart-legend-item-clickable {
  cursor: pointer;
}

.verdenskart-legend-item-color {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.verdenskart-legend-item-name {
  line-height: 24px;
  vertical-align: top;
}

.verdenskart-legend-item-info {
  margin-left: 8px;
  vertical-align: top;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
}

.verdenskart-legend-item-expand {
  margin-left: 34px;
  margin-bottom: 8px;
}
