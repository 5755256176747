.verdenskart-popup-climate .maplibregl-popup-content {
  padding: 16px 8px 8px;
}

.verdenskart-popup-climate caption {
  font-weight: bold;
}

.verdenskart-popup-climate th {
  text-align: left;
  font-weight: normal;
}

.verdenskart-popup-climate td {
  padding-left: 4px;
  text-align: right;
}
